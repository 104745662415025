import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { AssetVerificationComponent } from './asset-verification/asset-verification.component';
import { CreateUserComponent } from './create-user/create-user.component';


export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'create',
    loadChildren: () => import('./create-user/create-user.module')
      .then(m => m.CreateUserModule),
  },
 
  // { path: '', redirectTo: 'pages', pathMatch: 'full' },
  // { path: '**', redirectTo: 'pages' },

  
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', redirectTo: 'login'},
  { path: 'asset-verification', component: AssetVerificationComponent },
  { path: 'asset-verification', redirectTo: 'asset-verification' },
  // { path: 'create', component: CreateUserComponent },
 
 
  // { path: 'asset-verification', redirectTo: 'asset-verification'},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
