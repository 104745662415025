import { Component } from '@angular/core';

@Component({
  selector: 'ngx-two-columns-layout',
  styleUrls: ['./two-columns.layout.scss'],
  template: `

  <!-- altered layout -->
    <nb-layout windowMode>
    
      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})



export class TwoColumnsLayoutComponent {}


/*************original layout  ****************/


// <nb-layout windowMode>
    
// <nb-layout-column class="small">
// </nb-layout-column>

// <nb-layout-column>
//   <ng-content select="router-outlet"></ng-content>
// </nb-layout-column>

// <nb-layout-footer fixed>
//   <ngx-footer></ngx-footer>
// </nb-layout-footer>

// </nb-layout>
