import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Company } from "../models/company";
import { CompanyBrnch } from "../models/companybrnch";

import { Movement,} from "../models/movement";


import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from "../../environments/environment";
import { Employees } from "../models/employees";





@Injectable(
  {
    providedIn: 'root'
  }
)
export class MovementService
{
    constructor(private http: HttpClient) { }
    private baseUrl = `${environment.apiUrl}/fams/movement`;
   private baseUrlSearch = `${environment.apiUrl}/fams/movement/search`;

   
    createMovement(movement: Object): Observable<Object> {
      var obj=this.http.post(`${this.baseUrl}` + `/create`, movement,{observe:'response'});
          return obj;
  }
/*createInvoiceLine(invoiceLine: Object): Observable<Object> {
    var obj=this.http.post(`${this.baseUrl}` + `/create`, invoiceLine);
        return obj;
  }*/ 
  
  allInvoice(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  searchByAssetId(id : number){
    return this.http.get<Movement[]>(`${this.baseUrl}`+`/assetId` +`/${id}`)
  }
  searchByChildAssetNo(companyId,childNo){
    return this.http.get<Movement[]>(`${this.baseUrl}/childNo/${companyId}/${childNo}`)
  }
  searchByMovementId(id : number){
    return this.http.get<Movement[]>(`${this.baseUrl}`+`/find` +`/${id}`)
  }

  companySearch(id : number){
    return this.http.get<Company[]>(`${environment.apiUrl}/fams/company/find` +`/${id}`)
  }

  branchSearchFromCompanyId(companyId : number){
    return this.http.get<CompanyBrnch[]>(`${environment.apiUrl}/fams/companybranch/findCompany` +`/${companyId}`)
  }

  multiSearch(childAssetNo,parentAssetNo) {
    var obj :string = `${this.baseUrl}`+`/search`+`/${childAssetNo}`+`/${parentAssetNo}`
   return this.http.get(obj);
  }
  multipleSearchMovement(val : string){
    return this.http.get<Movement[]>(`${environment.apiUrl}/fams/movement/multiSearch` +`/${val}`)
  }
  getImageById(id :number){
   
    return this.http.get(`${environment.apiUrl}/fams/movementimage/downloadfile/` + `${id}`, {  responseType: 'blob' })//: {  responseType: 'application/octet-stream' }Observable<HttpResponse<Blob>>,{ responseType: ResponseContentType.Blob }
  }
  
  deleteImageById(id :number){
    return this.http.get(`${environment.apiUrl}/fams/movementimage/deletefile/` + `${id}`)
  }
  getEmployee(companyId){
    return this.http.get<Employees[]>(`${environment.apiUrl}/fams/employee/findAllByCompany/${companyId}`)
  }
  submitSecurityDetails(movement : Object): Observable<Object> {
    var obj=this.http.post(`${this.baseUrl}` + `/updateSecurityDetails`, movement,{observe:'response'});
        return obj;
  }
  submitTransportDetails(movement : Object): Observable<Object> {
    var obj=this.http.post(`${this.baseUrl}` + `/updateTransportDetails`, movement,{observe:'response'});
        return obj;
  }
  genrategatepassReport(companyId,assetNo){
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
      // 'responseType'  : 'blob' as 'json'        //This also worked
    };
    return this.http.get<any>(`${this.baseUrl}/createGatePassReport/`+companyId+"/"+assetNo, httpOptions);
  }
  expectedMovementReturn(movement : object){
    return this.http.post(`${this.baseUrl}/expectedRetun`,movement,{observe : 'response'})
  }
  getReportById(id :number){
   
    return this.http.get(`${environment.apiUrl}/fams/fileupload/downloadFile/` + `${id}`, { responseType: 'blob' })//: {  responseType: 'application/octet-stream' }Observable<HttpResponse<Blob>>,{ responseType: ResponseContentType.Blob }
  }
  searchMovementAssets(companyCode,empCode){
    return this.http.get(`${environment.apiUrl}/fams/movement/searchMovementAssets/` + `${companyCode}`+ `/${empCode}`, {observe : 'response'})
  }
  createAssetVerification(updateArr: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}` + `/createAssetVerification`, updateArr,{observe:'response'});
  }
}  