<!-- 
<div class="card" >
        <h5 class="card-title">Asset Verification</h5>
    <div class="card-body">
        <form >
                <div class="form-group row">
                     <div class="col-md-4">
                            <label class="labelclass">Company Code</label>
                            <input type="text" nbInput fieldSize="tiny" id="companyCode" />
                        </div>
                        <div class="col-md-4">
                            <label for="Asset No" class="labelclass"> Employee Code</label>
                            
                            <input type="text" nbInput id="empCode" nbInput fieldSize="tiny"
                                style="color: black" />

                        </div>

                           
                      </div>
                   
                                <div class="form-group row">
                                       

                                        <div class="col-md-4">
                                            <input type="button" style="height: 30px;" (click)="search()" value="Genrate Report" class="btn btn-secondary btn-sm" />
                                        </div>
                                        
                                    </div>

                             </form>
                                     
                                     
    </div>
</div> -->

<section id="cover" class="min-vh-100">
        <div id="cover-caption">
            <div class="container">
                <div class="row text-white">
                    <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                        <h1 class="display-4 py-2 text-truncate">Asset Verification</h1>
                        <div class="px-2">
                            <form  class="justify-content-center">
                                <div class="form-group">
                                    <label class="labelclass">Company Code</label>
                                    <input type="text" class="form-control" id="companyCode" />     
                                </div>
                                <div class="form-group">
                                    <label for="Asset No" class="labelclass"> Employee Code</label>
                                    <input type="text" class="form-control" id="empCode" (blur)="searchAssets()" />
                                </div>

                                <div class="table-responsive">
                                    <table>
                                        <thead class="thead">
                                            <tr>
                                                <th>Emp Code</th>
                                                <th>Asset No</th>
                                                <th>Serial No</th>
                                                <th>Asset Description</th>
                                                <th>Allocation Date</th>
                                                <th>Flag</th>
                                                <th>Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of assetArr; let i = index">
                                                <td>{{item.employeeId}}</td>
                                                <td>{{item.childAssetNo}}</td>
                                                <td>{{item.serialNo}}</td>
                                                <td>{{item.childAssetDesc}}</td>
                                                <td>{{item.dateofAllocation | date : 'dd-MM-yyyy'}}</td>
                                                <td> <select  id="flag{{i}}" (change)="checkFlag(i)" >
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    </select>
                                                </td>
                                                <td><input type="text" id="remark{{i}}" readonly /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button type="button" class="btn btn-primary btn-lg" (click)="update()">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>