export class MovementApproval{

    
    childAssetNo : string
    parentAssetNo	:	string
    approverStatus : string
    serialNo: string//float
    tagNo : string
    childAssetDesc : string
    itemCodeforNonCapex  : number
    purchaseOrderNo : string
   
}
export class SearchMovement{
    companyId : Number
    childAssetNo : string
    parentAssetNo	:	string
    approverStatus : string
    serialNo: string//float
    tagNo : string
    childAssetDesc : string
    itemCodeforNonCapex  : number
    purchaseOrderNo : string
    department : string;
    movementType : string;
    acknowledgedBy : string;
    acknowledgedByRecipent : string;
    expectedReturnDate : string;
    employeeId : Number;
}