import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Invoice } from "../models/invoice";
import { MapType } from "@angular/compiler";
import { environment } from "../../environments/environment";
import { Movement } from "../models/movement";



@Injectable(
  {
    providedIn: 'root'
  }
)
export class MovementApprovalService {
  constructor(private http: HttpClient) { }
  private baseUrl = `${environment.apiUrl}/fams/movement`;


  approvedallRecord(approvedArr: Object): Observable<Object> {
    console.log("approvedArr in service ",approvedArr)
    var obj = this.http.post(`${this.baseUrl}` + `/approvedAllRecord`, approvedArr,{observe:'response'});
    return obj;
  }
  rejectAllRecord(rejectArraay: Object): Observable<Object> {
    var obj = this.http.post(`${this.baseUrl}` + `/rejectAllRecord`, rejectArraay,{observe:'response'});
    return obj;
  }
  sendReminder(reminderArr: Object): Observable<Object>{
    console.log("approvedArr in service ",reminderArr)
    var obj = this.http.post(`${this.baseUrl}` + `/sendReminder`, reminderArr,{observe:'response'});
    return obj;
  }
  allInvoice(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  listMovementSearch(val : object) {
    return this.http.post<Movement[]>(`${this.baseUrl}/multiSearchMovement`,val);
  }
  checkOnLoginByDesg(companyId,employeeId){
    return this.http.get(`${environment.apiUrl}/fams/movement/checkLoginUserDesg/${companyId}/${employeeId}`)
  }

}   